/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

body {
  line-height: 1;
}

ol,
ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

/* End of the reset */

:root {
  --main-color: #3dbaff;
  --secondary-color: #3D59FF;
}

body {
  scroll-behavior: smooth;
}

a {
  text-decoration: none;
}

/* NAVBAR STYLING */

.nav-logo {
  width: 5rem;
}

.nav {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 6.25rem;
  width: 100%;
  background-color: white;
  position: relative;
}

.nav>.nav-header {
  display: inline;
}

.nav>.nav-header>.nav-title {
  display: inline-block;
  font-size: 22px;
  color: white;
  padding: 10px 10px 10px 10px;
}

.nav>.nav-btn {
  display: none;
}

.nav>.nav-links {
  display: inline;
  float: right;
  font-size: 1.2em;
  margin-right: 2.5rem;
}

.nav>.nav-links>a {
  display: inline-block;
  padding: 10px;
  text-decoration: none;
  border: 3px solid var(--secondary-color);
  border-radius: 30px;
  color: #3D59FF;
  margin-left: 1rem;
  font-family: forma-djr-text, sans-serif;
}

.nav>.nav-links>a:hover {
  transition: 0.3s;
  background-color: var(--secondary-color);
  color: white;
}

.nav>#nav-check {
  display: none;
}

.nav-logo {
  width: 3.5em;
  margin-left: 1.5em;
}

@media (max-width:700px) {
  .nav>.nav-links>a {
    color: white;
    margin-left: 0.5em;
    margin-top: 0.2em;
    padding: 7px;
  }

  .nav>.nav-btn {
    display: inline-block;
    margin-right: 1em;
    margin-top: 0.5em;
  }

  .nav>.nav-btn>label {
    display: inline-block;
    width: 50px;
    height: 50px;
  }

  .nav>.nav-btn>label>span {
    display: block;
    width: 45px;
    height: 13px;
    border-top: 3px solid var(--secondary-color);
  }

  .nav>.nav-links {
    position: absolute;
    display: block;
    width: 100%;
    background-color: var(--main-color);
    height: 0px;
    transition: all 0.3s ease-in;
    overflow: hidden;
    top: 100px;
    left: 0px;
    font-size: 1.7em;
  }

  .nav>.nav-links>a {
    display: block;
    width: 100%;
    border: none;
  }

  .nav>#nav-check:not(:checked)~.nav-links {
    height: 0px;
  }

  .nav>#nav-check:checked~.nav-links {
    height: calc(13rem - 100px);
    overflow-y: auto;
  }

  .home-header-title-cont,
  .header-title-1,
  .header-title-2 {
    margin: 0;
  }

  .nav-logo {
    width: 4rem;
    margin-left: 0.5em;
  }
}

.active {
  color: red;
}

/* HOME PAGE STYLING */

.hl-landing {
  margin-bottom: 5em;
}

.heading {
  font-size: 5rem;
  font-family: ff-nexus-typewriter, sans-serif;
  text-transform: lowercase;
}

/* HOME PAGE STYLING */

.hl-desktop {
  display: none;
}

.igorphoto {
  width: 18em;
}

.hl-mobile {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: calc(100vh - 6.25rem);
}

.landing-heading,
.skills-heading,
.projects-heading,
.testimonials-heading,
.about-heading,
.contact-heading {
  font-family: ff-nexus-typewriter, sans-serif;
  word-spacing: -10px;
  font-size: 4em;
}

.mobile-landing-texts {
  font-family: forma-djr-text, sans-serif;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 2em;
}

.landing-subheading,
.landing-button {
  font-family: forma-djr-text, sans-serif;
}

.landing-subheading {
  margin-top: 1em;
  font-size: 1.7em;
}

.landing-button {
  padding: 1em 1em;
  border: 3px solid var(--main-color);
  border-radius: 50px;
  font-size: 1.7em;
  margin-top: 2em;
  color: white;
  background-color: var(--main-color);
  box-shadow: 1px 1px 8px rgb(0, 0, 0, 0.4);
  cursor: pointer;
}

/* Skills section */

.skills-heading {
  text-align: center;
  margin-bottom: 0.5em;
}

.skillblock {
  width: 20rem;
  min-height: 40rem;
  background-color: rgb(61, 186, 255, 0.5);
  margin: auto;
  margin-bottom: 2em;
  border-radius: 50px;
  box-shadow: 1px 0px 8px rgb(189, 188, 188);
}

.skills-wrapper {
  margin-bottom: 2em;
}

.skills-img {
  width: 5em;
  padding: 2em;
}

.single-skill {
  width: 80%;
}

.skills-img-cont {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.skillname {
  font-family: forma-djr-text, sans-serif;
  font-size: 1.4em;
  margin-bottom: 0.2em;
}

.creativeskills-list {
  list-style-type: disc;
  margin-left: 3em;
  margin-right: 0.5em;
}

.cs-listitem {
  margin-bottom: 0.5em;
}

.second {
  margin: 0;
  text-align: left;
  margin-left: 3em;
  margin-right: 2em;
}

.coding-skills {
  margin: .5em 0 1em 2em;
}

/* Text after skills */

.betweentext {
  font-family: forma-djr-text, sans-serif;
  font-size: 1.8em;
}

.between-text-cont {
  margin: 0 1.5em;
  display: flex;
  justify-content: center;
  align-items: center;
}

.between-section {
  position: relative;
  width: 100vw;
}

.behindshape-left {
  position: absolute;
  width: 20em;
  height: 20em;
  background-color: var(--main-color);
  opacity: 0.2;
  border-radius: 73% 27% 36% 64% / 53% 40% 60% 47%;
  left: -10rem;
  top: 20%;
}

/* Projects section styling */

.projects-heading {
  font-size: 3em;
  text-align: center;
  margin: 1.5em 0;
}

.postimg {
  width: 17em;
  margin: 3em 1em;
  border-radius: 20px;
}

.singleproject {
  width: 20em;
  height: 30em;
  background-color: rgb(61, 186, 255, 0.5);
  margin: auto;
  margin-bottom: 2em;
  border-radius: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 1px 0px 8px rgb(161, 161, 161);
}

.project-title,
.project-overview {
  font-family: forma-djr-text, sans-serif;
}

.project-title {
  font-size: 1.7em;
  margin-bottom: 1em;
  text-align: center;
}

.project-overview {
  font-size: 1.3em;
  text-align: center;
}

.projpage-title {
  font-family: ff-nexus-typewriter, sans-serif;
  text-transform: lowercase;
  font-size: 3em;
  text-align: center;
  margin: 1em 0;
}

.projpage-content {
  font-family: forma-djr-text, sans-serif;
  font-size: 1.5em;
  margin: 0 1em;
}

.projpage-img-cont {
  display: flex;
  justify-content: center;
}

.projpage-img {
  width: 20em;
  margin: 2em 0;
}

.projpage-link a,
.projpage-link-desktop a {
  color: var(--main-color);
}

.projpage-link-cont {
  width: 100%;
  display: flex;
  justify-content: center;
}

.projpage-link,
.projpage-link-desktop {
  width: 9em;
  text-decoration: none;
  font-family: forma-djr-text, sans-serif;
  text-align: center;
  font-size: 2em;
  margin: 0 2em;
  margin-bottom: 1em;
  padding: 0.3em;
  border: 3px solid var(--main-color);
  color: var(--main-color);
  border-radius: 40px;
}

.projpage-link-desktop {
  display: none;
}

/* Slider with testimonials */

.testimonials-heading {
  font-size: 3em;
  text-align: center;
  margin: 1em 0 0.5em 0;
}

.slidee {
  height: 23em;
  margin: 0 2em;
  font-family: forma-djr-text, sans-serif;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.slidecontent {
  width: 90vw;
  font-size: 1.2em;
}

.slider-heading {
  font-size: 1.5em;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 1em;
}

.slider-name {
  margin-left: 0.5em;
}

.slider-img {
  width: 3em;
  border-radius: 50%;
}

.slidersection {
  margin-bottom: 5em;
}

/* End buttons */

.endbuttons-cont {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.endbutton {
  color: var(--main-color);
  border: 3px solid var(--main-color);
  padding: 0.7em;
  border-radius: 40px;
  font-family: forma-djr-text, sans-serif;
  font-size: 1.5em;
  margin-bottom: 1em;
  box-shadow: 1px 1px 8px rgba(173, 173, 173, 0.4);
  cursor: pointer;
}

#letstalk {
  color: #ffffff;
  background-color: var(--main-color);
}

/* ABOUT PAGE STYLING */

.about-content {
  margin: 1em;
  font-family: forma-djr-text, sans-serif;
  font-size: 1.5em;
}

.igorabout {
  width: 17em;
}

.about-photo-cont {
  display: flex;
  justify-content: center;
  margin-bottom: 3em;
}

.about-heading {
  text-align: center;
}

.about-heading-cont {
  margin: 3em 0;
}

.about-technologies-cont {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  margin: 3em 0;
}

.technology-logo {
  height: 3em;
}

.igordrums {
  display: none;
}

/* Footer styling */

.footer {
  background-color: var(--secondary-color);
  height: 12em;
  margin-top: 3em;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.footerlogo {
  width: 5em;
  margin-bottom: 2em;
}

.footername {
  font-family: ff-nexus-typewriter, sans-serif;
  color: #ffffff;
  font-size: 1.5em;
}

/* CONTACT PAGE styling */

.contact-heading {
  margin: 0.5em 0;
  text-align: center;
}

.contactpage {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.singlecontact {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding: 1em;
  border: 5px solid var(--main-color);
  width: 15em;
  height: 15em;
  margin-bottom: 2em;
  border-radius: 25px;
}

.contact-title {
  font-family: forma-djr-text, sans-serif;
  font-size: 1.5em;
  color: var(--main-color);
  margin: 1em 0;
}

.contact-img {
  width: 10em;
}

/* Media queries for desktop */

@media (min-width: 700px) {

  .hl-mobile {
    display: none;
  }

  .hl-desktop {
    display: block;
  }

  .landing-heading,
  .landing-button,
  .landing-subheading,
  .igorphoto {
    margin: 0;
  }

  .desktop-landing {
    position: relative;
    height: calc(100vh - 100px);
    width: 100%;
  }

  .igorphoto {
    width: 35%;
    position: absolute;
    left: 12%;
    top: 10%
      /* -webkit-filter: drop-shadow(2px 2px 8px rgb(34, 34, 34, 0.4));
    filter: drop-shadow(2px 2px 8px rgb(34, 34, 34, 0.4)); */
  }

  .landing-heading {
    font-size: 9vw;
    position: absolute;
    right: 12%;
    top: 10%;
  }

  .landing-subheading {
    font-size: 3vw;
    position: absolute;
    right: 12%;
    top: 35%;

  }

  .landing-button {
    font-size: 2.5vw;
    border-radius: 50em;
    position: absolute;
    right: 25%;
    top: 55%;
  }

  .landing-button:hover {
    transition: 0.6s;
    background-color: white;
    color: var(--main-color);
    box-shadow: 2px 1px 8px rgb(0, 0, 0, 0.2);
  }

  /* Skills section desktop */

  .skills-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
  }

  .skills-section {
    margin: 0 5em;
  }

  .skillblock {
    margin: 0;
    margin-top: 1em;
    width: 25em;
    height: 37em;
  }

  .skills-heading {
    text-align: left;
    font-size: 7em;
  }

  /* Projects styling */

  .singleproject {
    width: 70em;
    height: 25em;
    flex-direction: row;
    justify-content: left;
  }

  .singleproject:hover {
    cursor: pointer;
  }

  .projects-section {
    margin: 0 5em;
  }

  .project-img-cont {
    width: 50%;
    display: flex;
    justify-content: center;
  }

  .postimg {
    width: 25em;
    margin-left: 1em;
    border-radius: 20px;
  }

  .projects-heading {
    text-align: right;
    font-size: 7em;
    line-height: 1.2em;
    margin-bottom: 1em;
  }

  .project-title {
    font-size: 3em;
    text-align: left;
  }

  .project-overview {
    text-align: left;
    font-size: 1.6em;
  }

  .project-textwrap {
    width: 50%;
    padding-right: 1em;
  }

  /* Single project styles */

  .project-row {
    display: flex;
    height: calc(100vh - 100px);
  }

  .projpage-texts {
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
  }

  .projpage-content {
    margin: 0 1em;
  }

  .projpage-content p {
    margin: 1em 0;
  }

  .projpage-title {
    font-size: 5em;
    margin: 0 0.2em;
  }

  .projpage-other {
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .projpage-img {
    width: 40vw;
    margin: 3em 0;
    border-radius: 50px;
  }

  .projpage-link {
    display: none;
  }

  .projpage-link-desktop {
    display: block;
  }

  /* Slider */
  .slidee {
    height: 20em;
    margin: 0 20em;
  }

  .slidecontent {
    width: 55vw;
    font-size: 1.7em;
  }

  .testimonials-heading {
    font-size: 6em;
    text-align: left;
    margin-left: 1em;
    margin-top: 2em;
  }

  /* About desktop */

  .aboutpage {
    background-image: url("./img/aboutback2.svg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }

  .igorabout {
    width: 25em;
  }

  .about-content-cont {
    display: flex;
  }

  .about-wrapper {
    width: 60%;
  }

  .about-photo-cont {
    display: flex;
    flex-direction: column;
    width: 40%;
    justify-content: space-between;
    align-items: center;
  }

  .about-content {
    line-height: 1.4em;
  }

  .about-heading {
    text-align: right;
    font-size: 10em;
    margin-right: 0.5em;
  }

  .igordrums {
    display: block;
    width: 22em;
    border-radius: 56% 44% 69% 31% / 49% 60% 40% 51%;
  }

  .about-technologies-all {
    display: flex;
    width: 100%;
  }

  .about-technologies-cont {
    margin: 5em 0;
    display: flex;
    justify-content: space-around;
    width: 100%;
  }

  .technology-logo {
    display: block;
    height: 5em;
  }

  /* Endbuttons */

  .endbuttons-cont {
    margin-top: 8em;
  }

  .endbutton {
    font-size: 2em;
  }

  #letstalk:hover {
    box-shadow: 1px 1px 10px rgba(173, 173, 173);
    transition: 1s;
  }

  #resume:hover {
    box-shadow: 1px 1px 10px rgba(173, 173, 173);
    transition: 1s;
  }

  /* Footer desktop */

  .footer {
    flex-direction: row;
    justify-content: space-between;
    padding: 0 5em;
    height: 8em;
  }

  .footerlogo {
    margin-bottom: 0;
  }

  /* Contact page styling desktop */

  .contact-heading {
    font-size: 6em;
  }

  .contact-img {
    width: 6em;
  }

  .singlecontact {
    flex-direction: row;
    height: 8em;
    width: 30em;
    padding: 0;
  }

  .singlecontact:hover {
    box-shadow: 0px 0px 5px rgba(146, 146, 146, 0.9);
    transition: 0.5s;
  }

  .contact-first,
  .contact-second {
    width: 50%;
    display: flex;
  }

  .contact-first {
    justify-content: center;
  }

  .contact-title {
    margin: 0;
  }

}

/* Additional queries */

@media (min-width: 1200px) {
  .igorphoto {
    width: 35%;
    left: 10%;
    top: 0;
  }

  .landing-heading {
    font-size: 9vw;
    right: 12%;
    top: 10%;
  }

  .landing-subheading {
    font-size: 3vw;
    right: 15%;
    top: 40%;

  }

  .landing-button {
    font-size: 2.5vw;
    right: 25%;
    top: 65%;
  }
}

/* floating animation for technology logos */

.floating,
.floating2,
.floating3,
.floating4,
.floating5 {
  animation-duration: 15s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
  margin-left: 10px;
  margin-top: 5px;
}

.floating {
  animation-name: floating;
}

.floating2 {
  animation-name: floating2;
}

.floating3 {
  animation-name: floating3;
}

.floating4 {
  animation-name: floating4;
}

.floating5 {
  animation-name: floating5;
}

@keyframes floating {
  from {
    transform: translate(0, 0px);
  }

  30% {
    transform: translate(3px, -3px);
  }

  50% {
    transform: translate(5px, -6px);
  }

  85% {
    transform: translate(2px, 5px);
  }

  to {
    transform: translate(0, -0px);
  }
}

@keyframes floating2 {
  from {
    transform: translate(0, 0px);
  }

  25% {
    transform: translate(-5px, -7px);
  }

  50% {
    transform: translate(2px, -3px);
  }

  75% {
    transform: translate(6px, 7px);
  }

  to {
    transform: translate(0, -0px);
  }
}

@keyframes floating3 {
  from {
    transform: translate(0, 0px);
  }

  25% {
    transform: translate(3px, -4px);
  }

  50% {
    transform: translate(-4px, 3px);
  }

  75% {
    transform: translate(3px, -3px);
  }

  to {
    transform: translate(0, -0px);
  }
}

@keyframes floating4 {
  from {
    transform: translate(0, 0px);
  }

  25% {
    transform: translate(-3px, 2px);
  }

  50% {
    transform: translate(3px, -3px);
  }

  75% {
    transform: translate(1px, 2px);
  }

  to {
    transform: translate(0, -0px);
  }
}

@keyframes floating5 {
  from {
    transform: translate(0, 0px);
  }

  25% {
    transform: translate(-3px, 4px);
  }

  50% {
    transform: translate(3px, -3px);
  }

  75% {
    transform: translate(2px, 4px);
  }

  to {
    transform: translate(0, -0px);
  }
}